.forgot_pass {
  text-align: center;
  width: 328px;
}
.forgot_text {
  margin-bottom: 24px;
}
.forgot_title {
  font-family: 'Ubuntu';
  font-size: 22px;
  font-weight: 500;
}
.text_path {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
}
