.pas_otp {
}
.submit_button {
  height: 52px;
  border-radius: 6px;
  border: none;
  font-family: Ubuntu;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
}
.valid {
  background-color: #3b999a;
  color: #ffffff;
  &:hover {
    background-color: #0a8081;
  }
}
.invalid {
  color: #c6e1fd;
  background-color: #f2f8f8;
}

.text_link {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #0a8081;
  text-decoration: none;
}
.text_path {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-align: center;
  padding-bottom: 24px;
  margin: 0;
}
.OTP_title {
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin: 0;
  padding-bottom: 8px;
}
.resend_container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
}

.text_link.disabled {
  color: gray;
  cursor: not-allowed;
  text-decoration: none;
  pointer-events: none;
}
