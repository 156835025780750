form {
  position: relative;
}
.wrap_link {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  bottom: 55px;
  right: 0;
}

.text_link {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.5px;
  position: relative;
  color: #0a8081;
  margin-right: 10px;
  text-decoration: none;
}

.text_link a {
  cursor: pointer;
  width: auto;
}

.login_title {
  font-family: 'Ubuntu';
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding-bottom: 24px;
}
.login_container {
  text-align: center;
}
.login_form {
  gap: 8px;
  button {
    margin-top: 16px;
  }
}
