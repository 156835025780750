.customers {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.nav_panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #f2f8f8;
  min-width: 200px;
  max-width: 300px;
  flex-shrink: 0;
  overflow-x: auto;
}

.top_tabs,
.bottom_tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.navButton {
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 10px;
  padding-left: 10px;
  gap: 12px;
  width: 100%;
  max-width: 176px;
  height: 49px;
  border-radius: 8px;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.3px;
  text-align: left;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  color: #596987;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navButton img {
  width: 24px;
  height: 24px;
}

.navButton:hover {
  background-color: #f2f8f8;
}

.bottom_tabs {
  padding-bottom: 24px;
}

.nav_logo {
  padding: 24px 0 48px 24px;
}

.management_content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
}

.management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f8f8;
  height: 90px;
}

.header_title {
  font-family: Ubuntu;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  padding-left: 24px;
  margin: 0;
}

.header_button {
  border: none;
  background-color: white;
  cursor: pointer;
  padding: 0 20px;
}

.management_active_component {
  display: flex;
  flex-grow: 1;
  background-color: #e2ecf4;
  padding: 24px;
}

.content_area {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}
