.auth_input {
  position: relative;
  height: 69px;
  input {
    width: 308px;
    height: 50px;
    background-color: #f2f8f8;
    border: 1px solid #f2f8f8;
    border-radius: 8px;
    padding-left: 14px;
    padding-top: 15px;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    &::placeholder {
      color: #b8c0cd;
    }
    &:hover {
      border: 1px solid #c7f0dc;
    }
    &:focus {
      outline: none;
      border: 1px solid #0a8081;
      background-color: #ffffff;
    }
  }

  label {
    position: absolute;
    top: 8px;
    left: 15px;
    text-align: center;
    line-height: 20px;
    pointer-events: none;
    font-family: Ubuntu;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 2%;
  }

  .visibility_container {
    position: absolute;
    top: 40%;
    right: 0;
    transform: translateY(-50%);
    width: 22px;
    height: 26px;
  }
  .visibility_button {
    position: absolute;
    right: 6px;
    top: 15px;
    border: none;
    background: none;
    cursor: pointer;
    transform: translate(-50%, -50%);
    padding: 0;
    margin-top: 16px;
  }
  .visibility_off_icon {
    margin-top: 2px;
  }
}
